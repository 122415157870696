import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('component.modal.app.judge');
import { OnInit, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-component-modal-app-judge',
template: templateSource || '',
    styles: [`

/* file: /var/www/digitalon/project/main/build/src/app/component.modal.app.judge/view.scss */
.modal {
  display: block !important;
  background-color: rgba(0, 0, 0, 0.53);
}
.modal .modal-content {
  border: solid 1px #E6E7E9 !important;
}`],
})
export class ComponentModalAppJudgeComponent implements OnInit {
    public app: any;
    public message = "";
    public isshow: boolean = false;

    constructor(@Inject( Service)         public service: Service,    ) { }

    public async ngOnInit() {
        await this.service.init();
    }

    public async hide() {
        this.isshow = false;
        await this.service.render();
    }

    public async create(result) {
        const log = { ...this.app, message: this.message, result };
        await this.service.loading.show();
        const { code } = await wiz.call("create", log);
        await this.service.loading.hide();
        if (code !== 200) {
            await this.service.alert.show("심사중 에러발생!");
            throw new Error("create failed");
        }
        this.app = {};
        this.message = "";
        location.reload();
        this.isshow = false;
        await this.service.render();
    }
}

export default ComponentModalAppJudgeComponent;