import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.main');
import { OnInit, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-page-main',
template: templateSource || '',
    styles: [``],
})
export class PageMainComponent implements OnInit {
    public category = {
        list: [],
        listobj: {},
    };
    public ranking = [];
    public apps = {
        list: [],
        query: { category: "total" }
    };

    constructor(@Inject( Service)         public service: Service,    ) { }

    public async ngOnInit() {
        await this.service.init();
        this.extensionLogin();
        // await this.service.loading.show();
        await this.category_load();
        await this.ranking_load();
        await this.apps_load();
        // await this.service.loading.hide();
    }

    public async category_load() {
        const { code, data } = await wiz.call("category");
        if (code === 200) {
            this.category.list = data;
            this.category.listobj = data.reduce((acc, val) => {
                const { id, title } = val;
                return {
                    ...acc,
                    [id]: title,
                }
            }, {});
        }
        await this.service.render();
    }
    public async ranking_load() {
        const { code, data } = await wiz.call("ranking");
        if (code !== 200) {
            alert("ERROR: Ranking load failed");
            // await this.service.loading.hide();
            throw new Error("ranking load failed");
        }
        this.ranking = data;
    }
    public async apps_load(category = 'total') {
        // await this.service.loading.show();
        let body = this.apps.query;
        body.category = category;
        const { code, data } = await wiz.call("search", body);
        // await this.service.loading.hide();
        if (code !== 200) return;
        this.apps.list = data;
        await this.service.render();
    }

    public extensionLogin() {
        const DIGITALON_BROKER_ID = 'digitalon.kreonet.net';
    
        const messageHandler = async (e) => {
            try {
                let qs = location.search;
                window.history.replaceState({}, document.title, window.location.pathname);
                const searchParams = new URLSearchParams(qs);
                if (searchParams.get("extlogin") !== "true") return;
                const token = searchParams.get("__token__");
                const { id, key } = e.data;
                console.log("in digitalon", id, key, token);
                // if (id !== DIGITALON_BROKER_ID) return;
                // if (key !== "sidebar loaded") return;
                document.querySelector("#digital-on").contentWindow.postMessage({
                    id: DIGITALON_BROKER_ID,
                    key: "login_success",
                    data: token,
                }, '*');
                window.history.replaceState({}, document.title, window.location.pathname);
            } catch (err) {
                console.debug("error", err);
            }
        };
        window.addEventListener("message", messageHandler);
    }
}

export default PageMainComponent;